<template>
    <button :class="[size,color]">{{value}}</button>
</template>

<script>
    export default {
        name: "MaterialButton",
        props:{
            size: String,
            color: String,
            value: String
        }
    }
</script>

<style scoped lang="scss">
    button{
        border: 0;
        font-family: Roboto, "sans-serif";
        font-weight: 500;
        font-size: 17px;
        text-align: center;
        letter-spacing: 0;
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,.12);

        &.sm{
            width: 180px;
            height: 46px;
        }

        &.xs{
            width: 140px;
            height: 46px;
            font-size: 15px;
        }


        &.xxs{
            width: 110px;
            height: 46px;
            font-size: 13px;
        }

        &.md{
            width: 244px;
            height: 46px;

            @include for-desktop-up{
                width: 170px;
                height: 32px;
            }
        }

        &.purple{
            border-radius: 8px;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,.12);
            background: #6F64F8;
            font-size: 17px;
            color: white;

            @include for-desktop-up{
                box-shadow: 0 2px 4px 0 rgba(0,0,0,.12);
                border-radius: 4px;
                font-size: 12px;
            }
        }

        &.normal{
            font-weight: 400;
        }

        &.circle{
            border-radius: 22px;

        }

        &.white{
            font-family: Roboto, "sans-serif";
            color: #E83F91;
        }

        &.white-circle{
            background-color: white;
            border-radius: 8px;
            color: #E83F91;
        }

        &.pink{
            border: 1px solid #FFFFFF;
            color: #FFFFFF;
            text-align: center;
            background-color: #F54E5E;
            border-radius: 8px;
        }


        &:hover{
            filter: brightness(96%);
        }
    }
</style>
