<template>
    <div class="auth--view">
        <div class="auth--container parent__container">
            <div class="auth--loader" v-if="isLoading">
                <video-loader></video-loader>
            </div>
            <div class="form--container">
                <div class="form">
                    <h1>EYE-ADMIN</h1>
                    <div class="wrong--container" v-if="isWrongData">
                        <alert-text value="Ошибка! Неправильный логин или пароль"></alert-text>
                    </div>

                    <div class="input--container">
                        <material-input id="login" type="text" label="ЛОГИН/ID" icon-class="icon-user" v-model="login"></material-input>
                    </div>
                    <div class="input--container">
                        <material-input id="password" type="password" label="ПАРОЛЬ" icon-class="icon-hide" v-model="password"></material-input>
                    </div>
                    <div class="button--container">
                        <material-button color="purple" size="md" value="ВОЙТИ" @click.native="auth"></material-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import IMask from 'imask'
    import VideoLoader from "@/components/loader/VideoLoader";
    import AlertText from "@/components/alert/AlertText";
    import MaterialInput from "@/components/input/MaterialInput";
    import MaterialButton from "@/components/button/MaterialButton";
    import {WrongLoginOrPasswordException} from "@/exception/WrongLoginOrPasswordException";
    export default {
        name: "AuthView",
        components: {MaterialButton, MaterialInput, AlertText, VideoLoader},
        data(){
            return {
                isWrongData: false,
                isLoading: false,
                login : "",
                password : "",
            }
        },
        computed:{
            isMobile(){
                return screen.width < 992
            }
        },
        methods:{
            async auth(){
                try{
                    this.isLoading = true
                    const auth = await this.$store.dispatch("AUTH",{login : this.login,password: this.password})
                    setTimeout(async () => {
                        localStorage.removeItem("accessToken");
                        localStorage.removeItem("refreshToken");


                        localStorage.setItem("accessToken",auth.accessToken);
                        localStorage.setItem("refreshToken",auth.refreshToken);

                        await this.$router.push({name : 'MainView'})
                    }, 1000)
                    this.isWrongData = false;
                } catch (e) {
                    this.isLoading = false
                    if (e instanceof WrongLoginOrPasswordException){
                        this.isWrongData = true;
                    } else {
                        console.log(e)
                        alert(e.stack)
                    }
                }
            },
        },
        mounted () {
            const maskOptions = {
                mask: '+{7}(000)000-00-00'
            }
            this.mask = new IMask(document.getElementById('login'), maskOptions)
        }
    }
</script>

<style scoped lang="scss">
    .auth--view{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include for-mobile-devices{
        }

        .auth--container{
            background: #FFFFFF;
            border: 0 solid #F2F2F2;
            display: flex;
            transition: height 0.3s;
            position: relative;

            @include for-desktop-up{
                box-shadow: 0 1px 5px 1px rgba(88,88,88,0.40);
            }

            @include for-big-desktop-up{
                box-shadow: 0 2px 7px 1px rgba(88,88,88,0.40);
            }




            .form--container{
                background-color: white;

                @include for-desktop-up{
                    box-shadow: 0 1px 5px 1px rgba(88,88,88,0.40);
                }

                @include for-big-desktop-up{
                    box-shadow: 0 2px 7px 1px rgba(88,88,88,0.40);
                }

                .form{
                    margin-top: 34px;
                    margin-left: 35px;

                    @include for-desktop-up{
                        margin-top: 24px;
                        margin-left: 25px;
                    }

                    @include for-big-desktop-up{
                        margin-top: 34px;
                        margin-left: 35px;
                    }

                    h1{
                        font-family: Roboto, "sans-serif";
                        font-size: 24px;
                        color: #000000;
                        margin-bottom: 5px;


                        @include for-desktop-up{
                            font-family: Roboto, "sans-serif";
                            font-size: 16px;
                        }

                        @include for-big-desktop-up{
                            font-family: Roboto, "sans-serif";
                            font-size: 24px;
                        }
                    }

                    .wrong--container{
                        margin-top: 15px;
                    }

                    .input--container{
                        margin-top: 25px;

                        @include for-desktop-up{
                            margin-top: 18px;
                        }
                    }

                    .button--container{
                        margin-top: 30px;
                        display: flex;
                        align-items: center;
                        margin-bottom: 40px;

                        @include for-desktop-up{
                            margin-top: 20px;
                            margin-bottom: 28px;

                        }

                        a{
                            display: none;
                            margin-left: 0;
                        }
                    }


                }
            }

            &.parent__container{
                width: 350px;
                height: auto;

                @include for-desktop-up{
                    width: 245px;
                }

                @include for-big-desktop-up{
                    width: 350px;
                }

                .form--container{
                    width: 350px;
                    height: auto;

                    @include for-desktop-up{
                        width: 245px;
                    }

                    @include for-big-desktop-up{
                        width: 350px;
                    }
                }
            }
        }

    }


    .pink__icon{
        background-image: linear-gradient(90deg, #F87CB2 0%, #EE467B 100%);
        display: table;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        cursor: pointer;
        padding-left: 1px;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }

    .support--container{
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }


    .auth--loader{
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.16);
        position: absolute;
        z-index: 1;

        div{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 0;
        }
    }

    .prepare--text-container{
        position: absolute;
        bottom: -70px;
        width: 355px;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0;

        opacity: .3;
        font-family: Roboto, "sans-serif";
        font-weight: 300;
        letter-spacing: 0;
        font-size: 13px;
        color: #000000;
        line-height: 17px;
        text-align: center;

        @include for-mobile-devices{
            bottom: -115px;
        }

    }
</style>
