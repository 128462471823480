<template>
    <p :class="[size,{'success' : success}]">{{value}}</p>
</template>

<script>
    export default {
        name: "AlertText",
        props:{
            value: String,
            size: String,
            success: Boolean
        }
    }
</script>

<style scoped lang="scss">
    p{
        pointer-events: none;
        height: 40px;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 8px;
        background: #FFECEC;
        border: 1px solid rgba(255,0,0,.5);
        display: flex;
        align-items: center;
        width: calc(279px - 16px);
        font-family: 'Roboto', sans-serif;
        color: #D81919;
        font-size: 12px;
        font-weight: 100;


        @include for-desktop-up{
            font-size: 9px;
            width: calc(194px - 8px);
            padding-left: 4px;
            padding-right: 4px;
            height: 32px;
        }

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */


    }


    p.lg{
        width: calc(335px - 16px);

    }

    p.success{
        background: #EFFFDD;
        border: 1px solid rgba(126,211,33,.5);
        color: #417505
    }
</style>
